import {format, isFuture} from 'date-fns'

export function cn(...args) {
    return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes(data) {
    if (!data.edges) return []
    return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs({slug}) {
    return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture({publishedAt}) {
    return !isFuture(publishedAt)
}

export function getPostUrl(publishedAt, slug) {
    if (slug) {
        return `/post/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
    }
    return null
}

export function getFullUrl(path) {
    if (typeof window !== 'undefined') {
        let location = window.location
        return [location.protocol, '//', location.host, path].join('')
    }
    return path
}

export function buildImageObj(source = {asset: {}}) {
    const imageObj = {
        asset: {_ref: source.asset._ref || source.asset._id}
    }

    if (source.crop) imageObj.crop = source.crop
    if (source.hotspot) imageObj.hotspot = source.hotspot

    return imageObj
}

export function toPlainText(blocks) {
    if (!blocks) {
        return ''
    }
    return blocks
        .map(block => {
            if (block._type !== 'block' || !block.children) {
                return ''
            }
            return block.children.map(child => child.text).join('')
        })
        .join('\n\n')
}

export function isDomAvailable() {
    return typeof window !== 'undefined' && !!window.document && !!window.document.createElement;
}

export function slideUp(target, duration = 500) {
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        //alert("!");
    }, duration);
}

export function slideDown(target, duration = 500) {
    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;

    if (display === 'none')
        display = 'block';

    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}

export function slideToggle(target, duration = 500) {
    if (window.getComputedStyle(target).display === 'none') {
        return slideDown(target, duration);
    } else {
        return slideUp(target, duration);
    }
}